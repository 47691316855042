<template>
  <div>
    <b-list-group-item
      class="entity"
      @click="routeToInterface"
      @contextmenu.prevent="$refs.menu.open"
    >
      <h6 class="text-primary">
        {{ anInterface.name }}
      </h6>
      <div v-if="anInterface.description">
        {{ anInterface.description }}
      </div>
      <div v-else>
        <small class="text-muted">Description undefined</small>
      </div>
    </b-list-group-item>

    <!-- context -->
    <vue-context ref="menu">
      <li>
        <b-link
          v-for="data in menuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="data.method()"
        >
          <feather-icon
            :icon="data.icon"
            class="text-primary"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'ListGroupItemInterface',
  components: {
    VueContext,
    BLink,
  },
  props: {
    anInterface: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuData: [
        { icon: 'ChevronRightIcon', text: 'Open', method: () => this.routeToInterface() },
      ],
    }
  },
  methods: {
    routeToInterface() {
      this.$router.push(
        {
          name: 'interfaces',
          params: { focus: this.anInterface.id },
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
