<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="outline-secondary" @click="routeBack">
          Dismiss
        </b-button>

        <b-button variant="success" :disabled="loading" @click="onSubmit()">

          <span v-if="loading">
            <b-spinner small type="grow" />
            Saving...
          </span>

          <span v-else>
            Save Build Configuration
          </span>
        </b-button>
      </b-col>
    </b-row>
    <hr class="mb-2">
    <b-row>
      <b-col cols="6">
        <!-- Configuration Name -->
        <b-row>
          <b-col>
            <b-form-group label="Build Configuration Name" label-for="config-name">
              <b-form-input
                id="config-name"
                v-model="sub_obj.name"
                placeholder="Enter the name of the Build Configuration..."
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Version -->
        <b-row>
          <b-col>
            <b-form-group label="Version" label-for="config-version">
              <b-form-input
                id="config-version"
                v-model="sub_obj.version"
                type="text"
                placeholder="Build Configuration Version..."
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="config-date">Date</label>
            <b-input-group>
              <b-form-input
                id="config-date"
                v-model="sub_obj.created"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="sub_obj.created"
                  button-only
                  right
                  locale="en-AU"
                  aria-controls="config-date"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <!-- Configuration Description -->
        <b-row>
          <b-col>
            <b-form-group label="Description" label-for="config-desc">
              <b-form-textarea
                id="config-desc"
                v-model="sub_obj.description"
                placeholder="Give a brief description of the Build Configuration..."
                rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr class="mb-2">

    <h3>Allocation</h3>
    <b-row>
      <b-col>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area-release-entities"
        >
          <!-- Components -->
          <b-row class="mb-2">
            <b-col>
              <list-group-entity
                label="Components"
                :entity-array="sub_obj.objects"
                modal="associator-generic-entities-edit"
              />
            </b-col>
          </b-row>

          <!-- Instances -->
          <b-row class="mb-2">
            <b-col>
              <list-group-instance
                label="Instances"
                :entity-array="sub_obj.instances"
                modal="associator-generic-instances-edit"
              />
            </b-col>
          </b-row>
        </vue-perfect-scrollbar>
      </b-col>
      <b-col>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area-release-entities"
        >
          <!-- Interfaces -->
          <b-row class="mb-2">
            <b-col>
              <list-group-interface
                label="Interfaces"
                :entity-array="sub_obj.interfaces"
                modal="associator-generic-interfaces-edit"
              />
            </b-col>
          </b-row>

          <!-- Tests -->
          <b-row class="mb-2">
            <b-col>
              <list-group-tests
                label="Tests"
                :entity-array="sub_obj.tests"
                modal="associator-generic-tests-edit"
              />
            </b-col>
          </b-row>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>

    <div id="modal__ConfigEditModalsContainer">
      <!-- **** Modals **** -->
      <!-- Entities -->
      <associator-generic
        name="Entities"
        suffix="-edit"
        :associated-items="sub_obj.objects.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allEntities.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkConfigEntities"
      />

      <!-- Instances -->
      <associator-generic
        name="Instances"
        suffix="-edit"
        :associated-items="sub_obj.instances.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name + ' (' + item.component + ')',
          }
        })"
        :all-items="allInstances.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name + ' (' + item.component + ')',
          }
        })"
        @associated="linkConfigInstances"
      />

      <!-- Interfaces -->
      <associator-generic
        name="Interfaces"
        suffix="-edit"
        :associated-items="sub_obj.interfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.display_id + ' - ' + item.name,
          }
        })"
        :all-items="allInterfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.display_id + ' - ' + item.name,
          }
        })"
        @associated="linkConfigInterfaces"
      />

      <!-- Test Cases -->
      <associator-generic
        name="Tests"
        suffix="-edit"
        :associated-items="sub_obj.tests.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.ref_id + ' - ' + item.name,
          }
        })"
        :all-items="allTests.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.ref_id + ' - ' + item.name,
          }
        })"
        @associated="linkConfigTests"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupInstance from '@/components/Forms/ListGroups/ListGroupInstance.vue'
import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
import ListGroupTests from '@/components/Forms/ListGroups/ListGroupTest.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    AssociatorGeneric,
    ListGroupEntity,
    ListGroupInstance,
    ListGroupTests,
    ListGroupInterface,
    VuePerfectScrollbar,
  },
  data: () => ({
    sub_obj: {
      id: '',
      description: '',
      version: '',
      name: '',
      objects: [],
      interfaces: [],
      instances: [],
      tests: [],
      created: '',
    },
    allEntities: [],
    allInstances: [],
    allInterfaces: [],
    allTests: [],
    loading: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
    interfaces: [],
    tests: [],
    instances: [],
  }),
  computed: {
    ...mapState({
      selectedConfiguration: state => state.configurations.selectedConfiguration,
      entities: state => state.domainModel.performers,
    }),
  },
  watch: {
    default(newVal) {
      this.value = newVal
    },
    selectedConfiguration(newVal) {
      console.log('New Val: ', newVal)
      const t = this.sub_obj
      t.id = newVal.id
      t.name = newVal.name
      t.description = newVal.description
      t.version = newVal.version
      t.objects = newVal.components
      t.interfaces = newVal.interfaces
      t.instances = newVal.instances
      t.tests = newVal.tests
      t.created = newVal.created
    },
  },
  mounted() {
    if (this.selectedConfiguration) {
      console.log('Populating: ', this.selectedConfiguration)
      const t = this.sub_obj
      t.id = this.selectedConfiguration.id
      t.name = this.selectedConfiguration.name
      t.description = this.selectedConfiguration.description
      t.version = this.selectedConfiguration.version
      t.objects = this.selectedConfiguration.components
      t.interfaces = this.selectedConfiguration.interfaces
      t.instances = this.selectedConfiguration.instances
      t.tests = this.selectedConfiguration.tests
      t.created = this.selectedConfiguration.created
    } else {
      const t = this.sub_obj
      t.id = ''
      t.name = ''
      t.description = ''
      t.version = ''
      t.objects = []
      t.interfaces = []
      t.instances = []
      t.tests = []
      t.created = ''
    }
    this.getData()
    this.removeDuplicatesForAssociators()
    console.log('Populated: ', this.sub_obj)
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.loading = false
      const update = { model: this.$store.state.model.id }
      update.name = this.sub_obj.name
      update.description = this.sub_obj.description
      update.version = this.sub_obj.version
      update.created = this.sub_obj.created
      update.components = this.sub_obj.objects.map(x => x.id)
      update.instances = this.sub_obj.instances.map(x => x.id)
      update.interfaces = this.sub_obj.interfaces.map(x => x.id)
      update.tests = this.sub_obj.tests.map(x => x.id)

      if (this.sub_obj.id) {
        console.log('Update with this: ', update)
        this.$http.put(`/api/v2/domain_model/build_configuration/${this.sub_obj.id}`, update)
          .then(({ data }) => {
            this.routeBack(data.id)
          })
      } else {
        console.log('Create with this: ', this.update)
        this.$http.post('/api/v2/domain_model/build_configurations', update)
          .then(({ data }) => {
            this.routeBack(data.id)
          })
      }
    },
    routeBack(node) {
      this.$router.push(
        {
          name: 'model_configurations',
        },
      )
    },
    getData() {
      this.isDataLoaded = false
      const params = { model: this.$store.state.model.id }
      this.$http.get('/api/v2/interfaces/get_interfaces_simple', { params })
        .then(({ data }) => {
          console.log('Interfaces: ', data)
          this.interfaces = data.map(x => ({
            id: x.interface_properties.id,
            display_id: x.display_id,
            name: x.interface_properties.name,
          }))
        })
        .finally(() => {
          this.removeDuplicatesForAssociators()
          this.isDataLoaded = true
        })

      this.$http.get('/api/v2/tests', { params })
        .then(({ data }) => {
          console.log('Tests: ', data)
          this.tests = data.map(x => ({
            id: x.id,
            ref_id: x.ref_id,
            name: x.name,
          }))
        })
        .finally(() => {
          this.removeDuplicatesForAssociators()
          this.isDataLoaded = true
        })

      this.$http.get('/api/v2/domain_model/get_all_component_instances', { params })
        .then(({ data }) => {
          this.instances = data.map(x => ({ ...x }))
        })
        .finally(() => {
          this.removeDuplicatesForAssociators()
          this.isDataLoaded = true
        })
    },
    removeDuplicatesForAssociators() {
      // Remove already associated elements from the all arrays
      // -- Capabilities
      // -- Entities
      this.allEntities = this.entities.map(x => ({ ...x }))
      this.sub_obj.objects.forEach((elementA, indexA) => {
        this.allEntities.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allEntities.splice(indexB, 1)
          }
        })
      })
      // -- Instances
      this.allInstances = this.instances.map(x => ({ ...x }))
      this.sub_obj.instances.forEach((elementA, indexA) => {
        this.allInstances.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allInstances.splice(indexB, 1)
          }
        })
      })
      // -- Interfaces
      this.allInterfaces = this.interfaces.map(x => ({ ...x }))
      this.sub_obj.interfaces.forEach((elementA, indexA) => {
        this.allInterfaces.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allInterfaces.splice(indexB, 1)
          }
        })
      })
      // -- Tests
      this.allTests = this.tests.map(x => ({ ...x }))
      this.sub_obj.tests.forEach((elementA, indexA) => {
        this.allTests.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allTests.splice(indexB, 1)
          }
        })
      })
    },
    linkConfigEntities(ents) {
      console.log('Entities: ', ents, this.entities)
      const temp = this.findItemById(ents, this.entities)

      this.sub_obj.objects = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkConfigInstances(ents) {
      const temp = this.findItemById(ents, this.instances)

      this.sub_obj.instances = temp.map(x => ({
        id: x.id,
        name: x.name,
        component: x.component,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkConfigInterfaces(interfaces) {
      const temp = this.findItemById(interfaces, this.interfaces)

      this.sub_obj.interfaces = temp.map(x => ({
        id: x.id,
        name: x.name,
        display_id: x.display_id,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkConfigTests(tests) {
      const temp = this.findItemById(tests, this.tests)
      this.sub_obj.tests = temp.map(x => ({
        id: x.id,
        name: x.name,
        ref_id: x.ref_id,
      }))
      this.removeDuplicatesForAssociators()
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
  },
}
</script>

<style lang="scss">
.scroll-area-release-entities {
  max-height: 30vh;
}
</style>
